




































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import InventoriesTable from '@/components/InventoriesTable.vue'
import RequisitionsTable from '@/components/RequisitionsTable.vue'
import SalesTable from '@/components/SalesTable.vue'
import { ApiResponse, Project, Requisition, Sale } from '@/interfaces'
import { mapState } from 'vuex'

export default Vue.extend({
  components: {
    InventoriesTable,
    RequisitionsTable,
    SalesTable,
    Nav,
  },
  data() {
    return {
      inventories: [] as Project[],
      inventoryIds: [] as number[],
      requisitions: [] as Requisition[],
      requisitionIds: [] as number[],
      sales: [] as Sale[],
      saleIds: [] as number[],
      page: null,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    this.getInventories()
    this.getRequisitions()
    this.getSales()
  },
  methods: {
    getInventories() {
      this.inventories = [] as Project[]
      const loading = this.$loading({
        target: '#inventoriesTable',
        text: 'Chargement des données...',
      })

      this.$api
        .get('/inventories', {
          params: {
            deletedAt: true,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.inventories = apiResponse.data
          this.inventoryIds = apiResponse.data.forEach((project: Project) => {
            this.inventoryIds.push(project.id ?? -1)
          })
        })
        .finally(() => {
          loading.close()
        })
    },
    getRequisitions() {
      this.requisitions = [] as Requisition[]
      const loading = this.$loading({
        target: '#requisitionsTable',
        text: 'Chargement des données...',
      })

      this.$api
        .get('/requisitions', {
          params: {
            deletedAt: true,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.requisitions = apiResponse.data
          this.requisitionIds = apiResponse.data.forEach((requisition: Requisition) => {
            this.requisitionIds.push(requisition?.id ?? -1)
          })
        })
        .finally(() => {
          loading.close()
        })
    },
    getSales() {
      this.sales = [] as Sale[]
      const loading = this.$loading({
        target: '#salesTable',
        text: 'Chargement des données...',
      })

      this.$api
        .get('/sales', {
          params: {
            deletedAt: true,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.sales = apiResponse.data
          this.saleIds = apiResponse.data.forEach((sale: Sale) => {
            this.saleIds.push(sale?.id ?? -1)
          })
        })
        .finally(() => {
          loading.close()
        })
    },
  },
})
